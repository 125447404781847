import { locales } from '@/i18n'

export const extractYoutubeVideoId = (url: string) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match&&match[7].length==11)? match[7] : false;
}

export const getLocale = (locale: string) => {
  return locales.some((currentLocale) => currentLocale === locale)
    ? locale
    : 'uk'
}
