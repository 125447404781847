'use client'

import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useTranslations } from 'next-intl'
import NextLink from 'next/link'

const MainPromoVideo = () => {
  const t = useTranslations()

  const [isPlaying, setIsPlaying] = useState<boolean>(true)
  const videoElement = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    (async () => {
      if (isPlaying) {
        await videoElement.current?.play()
      } else {
        videoElement.current?.pause()
      }
    })()
  }, [isPlaying])

  const handleScrollToDown = () => {
    const element = document.querySelector('.primary-box')
    window.scrollTo({ top: element?.scrollHeight, behavior: 'smooth' })
  }

  return (
    <section className='primary-box'>
      <video
        ref={videoElement}
        loop={true}
        muted={true}
        className='video'
        preload='none'
        poster='/static/media/poster.webp'
      >
        <source src='/static/media/palche.mp4' type='video/mp4' />
        <source src='/static/media/palche.webm' type='video/webm' />
      </video>

      <div className='container-fluid'>
        <div className='row content'>
          <div className='box-title col-10 col-md-5'>
            <h1>{t('pages.main.video.title')}</h1>
            <NextLink
              target='_blank'
              href={'https://www.youtube.com/c/Palche'}
              className='see-presentation'
            >
              {t('action.see-presentation')}
            </NextLink>
          </div>
          <div className='video-buttons col-md-2 d-flex justify-content-center'>
            <button
              type='button'
              aria-label='Play'
              className={classNames('video-play-pause', {
                'play-my-video': !isPlaying,
              })}
              onClick={() => setIsPlaying(!isPlaying)}
            ></button>
          </div>
          <div
            className='scroll-down col-2 col-md-5 cursor-pointer'
            onClick={() => handleScrollToDown()}
          ></div>
        </div>
      </div>
    </section>
  )
}

export default MainPromoVideo