'use client'

import {Swiper, SwiperSlide, SwiperClass} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { EffectCoverflow } from 'swiper/modules';
import {ICatalog} from "@/entities";
import {CatalogCard} from "@/components/smart";
import classNames from "classnames";
import {useState} from "react";
import { useRouter } from 'next/navigation'

type TProps = {
	catalogs: ICatalog[]
	className?: string
}

const MainCatalogSlider = ({catalogs, className}: TProps) => {
	const [swiper, setSwiper] = useState<SwiperClass | null>(null);
	const [activeIndex, setActiveIndex] = useState(1);
	const router = useRouter();

	return (
		<section className={classNames("main-slider position-relative", className)}>
			<Swiper
				className='catalog-swiper'
				effect={'coverflow'}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={1}
				loop={true}
				coverflowEffect={{
					rotate: 0,
					stretch: 0,
					depth: 100,
					scale: 0.6,
					slideShadows: false,
					modifier: 1,
				}}
				breakpoints={{
					550: {
						slidesPerView: 1,
					},
					767: {
						slidesPerView: 1,
					},
					1100: {
						slidesPerView: 3,
					},
				}}
				modules={[EffectCoverflow]}
				onSwiper={setSwiper}
				onActiveIndexChange={(swiper) => {
					const activeIndex = swiper.realIndex + 1
					const catalogActiveIndex = activeIndex % catalogs.length > 0
						? activeIndex % catalogs.length
						: catalogs.length

					setActiveIndex(catalogActiveIndex)
				}}
			>
				<div className='slider-navigation position-absolute d-none d-lg-flex justify-content-between w-100'>
					<button
						type='button'
						className='prev'
						aria-label='Previous'
						onClick={(e) => {
							console.log('Clicl event')
							e.preventDefault()
							e.stopPropagation()
							swiper?.slidePrev()
						}}
					/>

					<button
						type='button'
						className='next'
						aria-label='Next'
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							swiper?.slideNext()
						}}
					/>
				</div>

				{[...catalogs, ...catalogs, ...catalogs].map((x, index) => {
					return (
						<SwiperSlide
							key={index}
							onClick={() => router.push(`catalog/${x.alias}`)}
						>
							<CatalogCard
								key={index}
								title={x.title}
								alias={x.alias}
								cover={x.cover}
							/>
						</SwiperSlide>
					)
				})}
			</Swiper>

			<div className='slider-nav'>
				<div className='bg-arrow left'></div>
				<div className='bg-arrow right'></div>
				{catalogs.length > 1 && (
					<ul className="d-lg-none">
						<li>
							<button
								type='button'
								className='prev'
								aria-label='Previous'
								onClick={() => {
									swiper?.slidePrev()
								}}
							></button>
						</li>
						<li className='count'>{activeIndex}<span>/</span>{catalogs.length}</li>
						<li>
							<button
								type='button'
								className='next'
								aria-label="Next"
								onClick={() => {
									swiper?.slideNext()
								}}
							></button>
						</li>
					</ul>
				)}
			</div>

		</section>
	)
}

export default MainCatalogSlider