'use client'

import {IProductVideo} from "@/entities";
import MainVideoCard from "@/components/pages/main/MainVideoCard";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useState } from 'react'
import ModalVideo from 'react-modal-video'
import { extractYoutubeVideoId } from '@/utils'
import { Autoplay, FreeMode } from 'swiper/modules'

type TProps = {
	videos: IProductVideo[]
}

const MainVideoList = ({videos}: TProps) => {
	const [isVideoPlayerModalShown, setIsVideoPlayerModalShown] = useState(false);
	const [videoId, setVideoId] = useState<string>('');

	const handleOpenVideoPlayer = (url: string) => {
		const youtubeVideoId = extractYoutubeVideoId(url)

		if (youtubeVideoId) {
			setVideoId(youtubeVideoId);
			setIsVideoPlayerModalShown(true);
		}
	}

	return (
		<>
			<div className="our-video">
				<Swiper
					className="carousel-our-video"
					slidesPerView={1}
					spaceBetween={30}
					pagination={{
						clickable: true,
					}}
					centeredSlides={true}
					speed={5000}
					autoplay={{
						delay: 0,
						disableOnInteraction: true,
					}}
					loop={true}
					freeMode={true}
					breakpoints={{
						550: {
							slidesPerView: 2,
						},
						767: {
							slidesPerView: 2,
						},
						1100: {
							slidesPerView: 4,
						},
						1600: {
							slidesPerView: 4,
						}
					}}
					modules={[FreeMode, Autoplay]}
				>
					{videos.map((x, index) => (
						<SwiperSlide key={index} className="item">
							<MainVideoCard
								title={x.title}
								cover={x.cover}
								alt={x.alt}
								openVideoPlayer={() => handleOpenVideoPlayer(x.location)}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>

			<ModalVideo
				channel='youtube'
				youtube={{autoplay: 1}}
				isOpen={isVideoPlayerModalShown}
				videoId={videoId}
				onClose={() => setIsVideoPlayerModalShown(false)}
			/>
		</>
	)
}

export default MainVideoList