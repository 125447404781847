'use client'

import Image from "next/image";

type TProps = {
	title: string
	cover: string
	alt: string
	openVideoPlayer: () => void
}

const MainVideoCard = ({title, cover, alt, openVideoPlayer}: TProps) => {
	return (
		<>
			<div
				className="swiper-slide cursor-pointer item"
				onClick={() => openVideoPlayer()}
			>
				<div className="img-video">
					<Image
						src={cover}
						alt={alt}
						width={500}
						height={500}
					/>
				</div>
				<button
					className="play-btn"
					type="button"
					aria-label="Play"
					onClick={() => openVideoPlayer()}
				></button>
				<div className="title">{title}</div>
			</div>
		</>
	)
}

export default MainVideoCard