'use client'

import NextLink from 'next/link'
import ProductCard from "@/components/smart/ProductCard";
import {IProduct} from "@/entities";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {useTranslations} from "next-intl";
import classNames from 'classnames'

type TProps = {
	products: IProduct[]
	className?: string
}

const MainProductList = ({products, className}: TProps) => {
	const t = useTranslations('pages.catalog')
	return (
		<section className={classNames("promotions-and-sales", className)}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 box-title">
						<h2 className="green-stripes">{t('promotions-and-sales.title')}</h2>
					</div>
					<div className="col-12 position-relative">
						<Swiper
							className="carousel-action"
							slidesPerView={1}
							pagination={{
								clickable: true,
							}}
							breakpoints={{
								550: {
									slidesPerView: 2,
								},
								767: {
									slidesPerView: 3,
								},
								1100: {
									slidesPerView: 4,
								},
								1600: {
									slidesPerView: 5,
								}
							}}
						>
							{products.map((x, index) => (
								<SwiperSlide key={index} className="item">
									<ProductCard
										title={x.title}
										alias={x.alias}
										cover={x.cover}
										images={x.images || []}
										badges={x.badges || []}
										price={x.priceText}
										discount={x.priceWithDiscountText}
										properties={x.properties}
									/>
								</SwiperSlide>
							))}
						</Swiper>

						<div className="open-all more-products">
							<NextLink href='/catalog'>{t('promotions-and-sales.more')}</NextLink>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default MainProductList